<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import { cloneDeep, some } from 'lodash-es'
import { queryDevicesApi } from '@/api/device'
import { getDeviceModelExpressionApi, getDeviceModelsApi } from '@/api/device-model'
import { updateLinkageConfigDetailApi } from '@/api/linkage'

const props = defineProps(['linkageDetail'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const formRef = ref<FormInstance>()
const deviceModelCode = ref()
const localConditionOnlines = ref<Record<string, any>>({
  deviceCode: '',
  deviceName: '',
  online: undefined,
  durationSeconds: 60,
})
const deviceModelList = ref<any[]>([])
const devices = ref<any[]>([])
const modelExpressions = ref<any[]>([])

watch(open, async (newOpen) => {
  if (newOpen) {
    deviceModelList.value = await getDeviceModelsApi()
    localConditionOnlines.value = {
      deviceCode: '',
      deviceName: '',
      online: undefined,
      durationSeconds: 60,
    }
    deviceModelCode.value = undefined
  }
})

function onSubmit() {
  formRef.value?.validate().then(async () => {
    const nextDetail = cloneDeep(props.linkageDetail)
    localConditionOnlines.value.linkageId = nextDetail.linkageId
    nextDetail.conditionOnlines.push(localConditionOnlines.value)
    await updateLinkageConfigDetailApi(nextDetail)
    emit('confirm')
    open.value = false
  })
}

async function onChangeDeviceModel(deviceModelCode: any, record: any) {
  const res = await queryDevicesApi({ size: 1000, deviceModelCode, current: 1 })
  devices.value = res.records
}

async function onChangeDevice(deviceCode: any, record: any) {
  localConditionOnlines.value.deviceName = record.deviceName
  modelExpressions.value = await getDeviceModelExpressionApi(record.deviceModelCode)
}

const okButtonDisabled = computed(() => {
  const requiredKeys = ['deviceCode', 'deviceName', 'online', 'durationSeconds']
  return some(requiredKeys, item => (localConditionOnlines.value[item] === undefined || localConditionOnlines.value[item] === ''))
})
</script>

<template>
  <a-modal v-model:open="open" title="设备上下线" :centered="true" :ok-button-props="{ disabled: okButtonDisabled }" @ok="onSubmit">
    <a-form ref="formRef" :model="localConditionOnlines" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="设备模型">
        <a-select v-model:value="deviceModelCode" :options="deviceModelList" :field-names="{ label: 'deviceModelName', value: 'deviceModelCode' }" @change="onChangeDeviceModel" />
      </a-form-item>
      <a-form-item v-if="devices.length > 0" label="设备" required>
        <a-select v-model:value="localConditionOnlines.deviceCode" :options="devices" :field-names="{ label: 'deviceName', value: 'deviceCode' }" @change="onChangeDevice" />
      </a-form-item>
      <a-form-item v-if="localConditionOnlines.deviceCode" label="在线/离线" required>
        <a-select v-model:value="localConditionOnlines.online" :options="[{ label: '在线', value: 'true' }, { label: '离线', value: 'false' }]" />
      </a-form-item>
      <a-form-item v-if="localConditionOnlines.online != null" label="持续时长" required>
        <a-input-number v-model:value="localConditionOnlines.durationSeconds" w-full>
          <template #addonAfter>
            秒
          </template>
        </a-input-number>
      </a-form-item>
      <a-alert v-if="okButtonDisabled" message="缺少必须字段，无法添加" type="warning" show-icon />
    </a-form>
  </a-modal>
</template>

<style lang="scss" scoped>

</style>
