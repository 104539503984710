<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import { cloneDeep, some } from 'lodash-es'
import { getDeviceModelsApi } from '@/api/device-model'
import { queryDevicesApi } from '@/api/device'
import { getDeviceAbilityListApi } from '@/api/device-ability'
import { updateLinkageConfigDetailApi } from '@/api/linkage'

const props = defineProps(['linkageDetail'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const formRef = ref<FormInstance>()
const deviceModelCode = ref()
const localTriggerEvent = ref<Record<string, string>>({
  deviceCode: '',
  deviceName: '',
  eventCode: '',
  eventName: '',
  outputDataExpression: '',
})
const deviceModelList = ref<any[]>([])
const devices = ref<any[]>([])
const abilities = ref<any[]>([])
// const { addTriggerEvent, linkageDetail } = useDetailState()

watch(open, async (newOpen) => {
  if (newOpen) {
    deviceModelList.value = await getDeviceModelsApi()
    localTriggerEvent.value = {
      deviceCode: '',
      deviceName: '',
      eventCode: '',
      eventName: '',
      outputDataExpression: '',
    }
    deviceModelCode.value = undefined
  }
})

function onSubmit() {
  formRef.value?.validate().then(async () => {
    const nextDetail = cloneDeep(props.linkageDetail)
    localTriggerEvent.value.linkageId = nextDetail.linkageId
    nextDetail.triggerEvents.push(localTriggerEvent.value)
    await updateLinkageConfigDetailApi(nextDetail)
    // await addTriggerEvent(localTriggerEvent.value)
    emit('confirm')

    open.value = false
  })
}

async function onChangeDeviceModel(deviceModelCode: any, xx: any) {
  const res = await queryDevicesApi({ size: 1000, deviceModelCode, current: 1 })
  devices.value = res.records
}

async function onChangeDevice(deviceCode: any, record: any) {
  localTriggerEvent.value.deviceName = record.deviceName
  const abilityList = await getDeviceAbilityListApi(deviceCode)
  abilities.value = abilityList.filter(ability => ability.abilityType === 'EVENT')
}

function onChangeAbility(abilityCode: any, record: any) {
  localTriggerEvent.value.eventName = record.abilityName
}

const okButtonDisabled = computed(() => {
  const requiredKeys = ['deviceCode', 'deviceName', 'eventCode', 'eventName']
  return some(requiredKeys, item => (localTriggerEvent.value[item] === undefined || localTriggerEvent.value[item] === ''))
})
</script>

<template>
  <a-modal v-model:open="open" title="设备事件" :centered="true" :ok-button-props="{ disabled: okButtonDisabled }" @ok="onSubmit">
    <a-form ref="formRef" :model="localTriggerEvent" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="设备模型" required>
        <a-select v-model:value="deviceModelCode" :options="deviceModelList" :field-names="{ label: 'deviceModelName', value: 'deviceModelCode' }" @change="onChangeDeviceModel" />
      </a-form-item>
      <a-form-item v-if="devices.length > 0" label="设备" required>
        <a-select v-model:value="localTriggerEvent.deviceCode" :options="devices" :field-names="{ label: 'deviceName', value: 'deviceCode' }" @change="onChangeDevice" />
      </a-form-item>
      <a-form-item v-if="abilities.length > 0" label="设备能力" required>
        <a-select v-model:value="localTriggerEvent.eventCode" :options="abilities" :field-names="{ label: 'abilityName', value: 'abilityCode' }" @change="onChangeAbility" />
      </a-form-item>
      <a-form-item v-if="localTriggerEvent.eventCode" label="规则表达式">
        <a-input v-model:value="localTriggerEvent.outputDataExpression" />
      </a-form-item>
      <a-alert v-if="okButtonDisabled" message="缺少必须字段，无法添加" type="warning" show-icon />
    </a-form>
  </a-modal>
</template>
