<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import { cloneDeep, filter, isObject, map, pick, some } from 'lodash-es'
import { queryDevicesApi } from '@/api/device'
import { getDeviceAbilityModelListApi, getDeviceModelExpressionApi, getDeviceModelsApi } from '@/api/device-model'
import { getDeviceAbilityListApi } from '@/api/device-ability'
import { updateLinkageConfigDetailApi } from '@/api/linkage'

const props = defineProps(['linkageDetail'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const formRef = ref<FormInstance>()
const localActionExpression = ref<Record<string, any>>({
  abilityCode: '',
  abilityName: '',
  deviceCode: '',
  deviceName: '',
  data: undefined,
})
const deviceModelCode = ref()
const deviceModelList = ref<any[]>([])
const devices = ref<any[]>([])
const deviceAbilityList = ref<any[]>([])
const deviceAbilityModelList = ref<any[]>([])
const selectedAbilityModel = ref()

const okButtonDisabled = computed(() => {
  const requiredKeys = ['deviceCode', 'abilityCode']
  return some(requiredKeys, item => (localActionExpression.value[item] === undefined || localActionExpression.value[item] === ''))
})

watchEffect(async () => {
  if (open.value) {
    deviceModelList.value = await getDeviceModelsApi()
    localActionExpression.value = {
      abilityCode: '',
      abilityName: '',
      deviceCode: '',
      deviceName: '',
      data: undefined,
    }
    deviceModelCode.value = undefined
  }
})

async function onChangeDeviceModel(deviceModelCode: any) {
  const res = await queryDevicesApi({ size: 1000, deviceModelCode, current: 1 })
  devices.value = res.records
  localActionExpression.value = {}
}

async function onChangeDevice(_deviceCode: any, { deviceName, deviceCode, deviceModelCode }: any) {
  localActionExpression.value.deviceName = deviceName
  localActionExpression.value.data = ''
  deviceAbilityList.value = await getDeviceAbilityListApi(deviceCode)
  const abilityModelList = await getDeviceAbilityModelListApi(deviceModelCode)
  // deviceAbilityModelList.value = filter(abilityModelList, (abilityModel) => {
  //   // TODO 添加SERVICE类型
  //   // if (!(['SERVICE', 'PROPERTY'].includes(abilityModel.abilityType)) || abilityModel.detail.dataType.type === 'ARRAY' || abilityModel.detail.dataType.type === 'STRUCT')
  //   //   return false

  //   // return abilityModel.abilityType !== 'PROPERTY' || abilityModel.detail?.accessMode === 'READ_WRITE'
  //   return abilityModel.abilityType === 'PROPERTY' && abilityModel.detail?.accessMode === 'READ_WRITE'
  // })

  deviceAbilityModelList.value = await filterAbilities(abilityModelList, ['PROPERTY'])

  localActionExpression.value.abilityCode = ''
  localActionExpression.value.abilityName = ''
}

/** 筛选需要留下的设备能力类型的设备能力模型 */
async function filterAbilities(abilityModels: DeviceAbilityModel[], leaveTypes: ('SERVICE' | 'PROPERTY' | 'EVENT' | 'PROFILE' | undefined)[]) {
  const filteredAbilityModels = abilityModels.filter((item) => {
    // 检查是否在 leaveTypes 中
    if (!leaveTypes.includes(item.abilityType)) {
      return false
    }
    // 检查 accessMode
    return item.abilityType !== 'PROPERTY' || (item.detail as NormalDetail).accessMode === 'READ_WRITE'
  })

  const bandedAbilities = await getDeviceAbilityListApi(localActionExpression.value.deviceCode as string)
  const abilityCodes = new Set(bandedAbilities.map(item => item.abilityCode)) // 使用 Set 来提高查找性能

  return filteredAbilityModels.filter(item => abilityCodes.has(item.abilityCode)) // 使用 Set 的 has 方法进行过滤
}

function onChangeAbility(_abilityCode: any, deviceAbilityModel: any) {
  selectedAbilityModel.value = deviceAbilityModel
  localActionExpression.value.abilityName = deviceAbilityModel.abilityName
  if (selectedAbilityModel.value.detail.dataType.type === 'STRUCT' || selectedAbilityModel.value.detail.dataType.type === 'ARRAY') {
    localActionExpression.value.data = {}
  }
  else {
    localActionExpression.value.data = ''
  }
}

function onSubmit() {
  formRef.value?.validate().then(async () => {
    const nextDetail = cloneDeep(props.linkageDetail)
    localActionExpression.value.linkageId = nextDetail.linkageId
    if (isObject(localActionExpression.value.data)) {
      localActionExpression.value.data = JSON.stringify(localActionExpression.value.data)
    }

    nextDetail.actions.push(pick(localActionExpression.value, ['abilityCode', 'data', 'deviceCode', 'linkageId']))
    await updateLinkageConfigDetailApi(nextDetail)
    open.value = false
    emit('confirm')
  })
}

function getSelectOptions(specs: any) {
  return map(specs, (value, key) => ({ label: value, value: key }))
}
</script>

<template>
  <a-modal v-model:open="open" width="60%" title="设备控制" centered :ok-button-props="{ disabled: okButtonDisabled }" @ok="onSubmit">
    <a-form ref="formRef" :model="localActionExpression" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="设备模型">
        <a-select v-model:value="deviceModelCode" :options="deviceModelList" :field-names="{ label: 'deviceModelName', value: 'deviceModelCode' }" @change="onChangeDeviceModel" />
      </a-form-item>
      <a-form-item v-if="devices.length > 0" label="设备" required>
        <a-select v-model:value="localActionExpression.deviceCode" :options="devices" :field-names="{ label: 'deviceName', value: 'deviceCode' }" @change="onChangeDevice" />
      </a-form-item>
      <a-form-item v-if="deviceAbilityModelList.length > 0" label="设备能力" required>
        <a-select v-model:value="localActionExpression.abilityCode" :options="deviceAbilityModelList" :field-names="{ label: 'abilityName', value: 'abilityCode' }" @change="onChangeAbility" />
      </a-form-item>
      <div v-if="selectedAbilityModel" :label="selectedAbilityModel.detail">
        <a-form-item v-if="selectedAbilityModel.detail.dataType.type === 'TEXT'" :label="selectedAbilityModel.abilityName">
          <a-input v-model:value="localActionExpression.data" :placeholder="`文本长度${selectedAbilityModel.detail.dataType.specs.length ?? '不限'}`" />
        </a-form-item>
        <a-form-item v-if="selectedAbilityModel.detail.dataType.type === 'INT' || selectedAbilityModel.detail.dataType.type === 'DECIMAL'" :label="selectedAbilityModel.abilityName">
          <a-input-number v-model:value="localActionExpression.data" :max="selectedAbilityModel.detail.dataType.specs.max" :min="selectedAbilityModel.detail.dataType.specs.min" :addon-after="selectedAbilityModel.detail.dataType.specs.unit ?? '无单位'" />
        </a-form-item>
        <a-form-item v-if="selectedAbilityModel.detail.dataType.type === 'BOOL' || selectedAbilityModel.detail.dataType.type === 'ENUM'" :label="selectedAbilityModel.abilityName">
          <a-select v-model:value="localActionExpression.data" :options="getSelectOptions(selectedAbilityModel.detail.dataType.specs)" />
        </a-form-item>
        <a-form-item v-if="selectedAbilityModel.detail.dataType.type === 'DATE' || selectedAbilityModel.detail.dataType.type === 'DAY'" :label="selectedAbilityModel.abilityName">
          <a-date-picker v-model:value="localActionExpression.data" :format="selectedAbilityModel.detail.dataType.type === 'DAY' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'" :value-format="selectedAbilityModel.detail.dataType.type === 'DAY' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'" />
        </a-form-item>
        <div v-if="selectedAbilityModel.detail.dataType.type === 'STRUCT'">
          <a-form-item v-for="(struct, index) in selectedAbilityModel.detail.dataType.specs" :key="`${index}struct`" :label="struct.name">
            <a-input v-if="struct.dataType.type === 'TEXT'" v-model:value="localActionExpression.data[struct.identifier]" :placeholder="`文本长度${struct.dataType.specs.length ?? '不限'}`" />
            <a-input-number v-if="struct.dataType.type === 'INT' || struct.dataType.type === 'DECIMAL'" v-model:value="localActionExpression.data[struct.identifier]" :max="struct.dataType.specs.max" :min="struct.dataType.specs.min" :addon-after="struct.dataType.specs.unit ?? '无单位'" />
            <a-select v-if="struct.dataType.type === 'BOOL' || struct.dataType.type === 'ENUM'" v-model:value="localActionExpression.data[struct.identifier]" :options="getSelectOptions(struct.dataType.specs)" />
            <a-date-picker v-if="struct.dataType.type === 'DATE' || struct.dataType.type === 'DAY'" v-model:value="localActionExpression.data[struct.identifier]" :format="struct.dataType.type === 'DAY' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'" :value-format="struct.dataType.type === 'DAY' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'" />
          </a-form-item>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>
