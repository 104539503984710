<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue'
import ActionVideoEdit from './action-video-edit.vue'
import ActionExpressionEdit from './action-expression-edit.vue'
import { useDetailState } from './detail-context'

defineProps(['actionDetail'])
const emit = defineEmits(['delete'])
const { linkageDetail, refresh } = useDetailState()

const actionVideoModalOpen = ref(false)
const deviceControlModalOpen = ref(false)

function onDelete(type: string, id: any) {
  emit('delete', [type, id])
}

async function onRefresh() {
  await refresh(linkageDetail.value.linkageId)
}

function onAddVideoLinkage() {
  actionVideoModalOpen.value = true
}

function onAddExpression() {
  deviceControlModalOpen.value = true
}
</script>

<template>
  <a-space direction="vertical" w-full :size="20">
    <a-table
      :data-source="actionDetail.actionVideos" :columns="[
        { title: '设备名称', dataIndex: 'deviceName' },
        { title: '设备编号', dataIndex: 'videoDeviceCode' },
        { title: '操作', dataIndex: 'operation' },
      ]" :pagination="false" :scroll="{ y: 220 }" bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a-space direction="horizontal">
            <!-- <a-button type="link">
              编辑
            </a-button> -->
            <a-popconfirm title="确定删除？" @confirm="() => onDelete('actionVideos', record.id)">
              <a-button type="link" danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
      <template #title>
        <div w-full flex justify-between items-center>
          <span text-16px font-bold>视频联动</span>
          <a-button type="primary" @click="onAddVideoLinkage">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
        </div>
      </template>
    </a-table>
    <a-table
      :data-source="actionDetail.actions" :columns="[
        { title: '设备名称', dataIndex: 'deviceName' },
        { title: '设备编号', dataIndex: 'deviceCode' },
        { title: '能力名称', dataIndex: 'abilityName' },
        { title: '能力编号', dataIndex: 'abilityCode' },
        { title: '操作', dataIndex: 'operation' },
      ]" :pagination="false" bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a-space direction="horizontal">
            <a-popconfirm title="确定删除？" @confirm="() => onDelete('actions', record.id)">
              <a-button type="link" danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
      <template #title>
        <div w-full flex justify-between items-center>
          <span text-16px font-bold>设备控制</span>
          <a-button type="primary" @click="onAddExpression">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
        </div>
      </template>
    </a-table>

    <ActionVideoEdit v-model:open="actionVideoModalOpen" :linkage-detail="linkageDetail" @confirm="onRefresh" />
    <ActionExpressionEdit v-model:open="deviceControlModalOpen" :linkage-detail="linkageDetail" @confirm="onRefresh" />
  </a-space>
</template>
