<script setup lang="ts">
import { cloneDeep, isBoolean, isNumber } from 'lodash-es'
import { addLinkageConfigApi, updateLinkageConfigApi } from '@/api/linkage'

const props = defineProps(['linkage'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const operation = ref<'add' | 'update'>('add')
const formRef = ref()
const localLinkage = ref({ linkageName: '', cron: '', enabled: 'false', startAt: undefined, endAt: undefined, description: '' })

watch(open, async (newOpen) => {
  if (newOpen) {
    if (props.linkage) {
      operation.value = 'update'
      localLinkage.value = cloneDeep(props.linkage)
    }
    else {
      localLinkage.value = { linkageName: '', cron: '', enabled: 'false', startAt: undefined, endAt: undefined, description: '' }
      operation.value = 'add'
    }
  }
})

async function onSubmit() {
  formRef.value?.validate().then(async () => {
    let success = false
    if (operation.value === 'add') {
      const res = await addLinkageConfigApi(localLinkage.value)
      if (isNumber(res)) {
        success = true
      }
    }
    if (operation.value === 'update') {
      const res = await updateLinkageConfigApi(localLinkage.value)
      if (isBoolean(res) && res) {
        success = true
      }
    }

    if (success)
      emit('confirm')
  })
}
</script>

<template>
  <a-modal v-model:open="open" :centered="true" width="35%" :title="operation === 'add' ? '新增' : '修改'" @ok="onSubmit">
    <a-form
      ref="formRef"
      name="custom-validation"
      :model="localLinkage"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-item has-feedback label="规则名称" name="linkageName" required>
        <a-input v-model:value="localLinkage.linkageName" />
      </a-form-item>
      <a-form-item label="cron表达式" name="cron">
        <a-input v-model:value="localLinkage.cron" />
      </a-form-item>
      <a-form-item label="启用状态" name="enabled">
        <a-switch v-model:checked="localLinkage.enabled" checked-children="启用" un-checked-children="禁用" />
      </a-form-item>
      <a-form-item label="开始时间" name="startAt">
        <a-date-picker v-model:value="localLinkage.startAt" format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
      </a-form-item>
      <a-form-item label="结束时间" name="endAt">
        <a-date-picker v-model:value="localLinkage.endAt" format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
      </a-form-item>
      <a-form-item label="备注" name="description">
        <a-input v-model:value="localLinkage.description" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style lang="scss" scoped>

</style>
