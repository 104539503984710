<script setup lang="ts">
import { QuestionCircleOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
import ActionDetail from './action-detail.vue'
import ConditionDetail from './condition-detail.vue'
import TriggerDetail from './trigger-detail.vue'
import { useDetailProvider } from './detail-context'
import { getLinkageConfigDetailByIdApi, updateLinkageConfigDetailApi } from '@/api/linkage'

type detailType = 'trigger' | 'condition' | 'action'
const props = defineProps(['linkage'])
const open = defineModel<boolean>('open')
const pageType = defineModel('pageType')
const radioValue = ref<detailType>('trigger')
const linkageDetail = ref<any>()
const typeMap = new Map<detailType, string>([
  ['trigger', '触发器'],
  ['condition', '执行条件'],
  ['action', '执行动作'],
])
const remindMap = new Map<detailType, string>([
  ['trigger', '触发器可为空，只要满足任意一个触发器即可触发。'],
  ['condition', '执行条件可为空，所有执行条件都必须满足才会执行。'],
  ['action', ''],
])
const actionDetail = ref({})
const conditionDetail = ref({})
const triggerDetail = ref({})

useDetailProvider(linkageDetail, getLinkageDetail)

function goBack() {
  // pageType.value = 'list'
  open.value = false
}

watch(() => props.linkage, async (newVal) => {
  if (newVal) {
    await getLinkageDetail(newVal.id)
  }
}, { immediate: true })

const title = computed(() => typeMap.get(radioValue.value))

async function getLinkageDetail(id: number) {
  linkageDetail.value = await getLinkageConfigDetailByIdApi(id)
  translateDetail()
}

function translateDetail() {
  const { actionVideos, actions, conditionExpressions, conditionOnlines, triggerEvents, triggerExpressions, triggerOnofflines } = linkageDetail.value
  actionDetail.value = { actions, actionVideos }
  conditionDetail.value = { conditionExpressions, conditionOnlines }
  triggerDetail.value = { triggerEvents, triggerExpressions, triggerOnofflines }
}

async function onDelete([type, id]: [string, number]) {
  const nextDetail = cloneDeep(linkageDetail.value)
  const aim = nextDetail[type]
  const toDeleteIndex = aim.findIndex((item: any) => item.id === id)
  nextDetail[type].splice(toDeleteIndex, 1)
  await updateLinkageConfigDetailApi(nextDetail)
  await getLinkageDetail(nextDetail.linkageId)
}
</script>

<template>
  <div v-if="open" pos-absolute top-0 bottom-0 left-0 right-0 overflow-hidden flex flex-col style="background-color: #f0f2f5;">
    <content-header :title="linkage?.linkageName ?? ''" :need-back="true" @on-back="goBack">
      <template #extra>
        <a-tag :color="linkage?.enabled ? 'green' : 'warning'">
          {{ linkage?.enabled ? '启用' : '禁用' }}
        </a-tag>
      </template>
    </content-header>

    <flex-content>
      <div rounded p-12px style="background: var(--bg-color);">
        <a-descriptions size="small" :column="2">
          <a-descriptions-item label="开始时间">
            {{ linkage?.startAt ?? '不限' }}
          </a-descriptions-item>
          <a-descriptions-item label="结束时间">
            {{ linkage?.endAt ?? '不限' }}
          </a-descriptions-item>
          <a-descriptions-item label="Corn表达式">
            {{ linkage?.cron ?? '无' }}
          </a-descriptions-item>
        </a-descriptions>
      </div>

      <a-card mt-10px flex-1 overflow-hidden :body-style="{ height: 'calc(100% - 56px)', overflow: 'hidden' }">
        <template #title>
          <a-row>
            <a-col :span="14">
              <div items-center flex justify-start font-bold h-full>
                <a-space>
                  <span text-18px>{{ title }}</span>
                  <a-tooltip v-if="remindMap.get(radioValue) !== ''" placement="top" :title="remindMap.get(radioValue)">
                    <QuestionCircleOutlined style="font-size: '16px';" />
                  </a-tooltip>
                </a-space>
              </div>
            </a-col>
            <a-col :span="10">
              <div flex justify-end items-center w-full>
                <a-radio-group v-model:value="radioValue">
                  <a-radio-button value="trigger">
                    触发器
                  </a-radio-button>
                  <a-radio-button value="condition">
                    执行条件
                  </a-radio-button>
                  <a-radio-button value="action">
                    执行动作
                  </a-radio-button>
                </a-radio-group>
              </div>
            </a-col>
          </a-row>
        </template>
        <scroll>
          <ActionDetail v-if="radioValue === 'action'" :action-detail="actionDetail" @delete="onDelete" />
          <ConditionDetail v-else-if="radioValue === 'condition'" :condition-detail="conditionDetail" @delete="onDelete" />
          <TriggerDetail v-else-if="radioValue === 'trigger'" :trigger-detail="triggerDetail" @delete="onDelete" />
        </scroll>
      </a-card>
    </flex-content>
  </div>
</template>
