<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue'
import TriggerEventEdit from './trigger-event-edit.vue'
import TriggerExpressionEdit from './trigger-expression-edit.vue'
import TriggerOnofflineEdit from './trigger-onoffline-edit.vue'
import { useDetailState } from './detail-context'

defineProps(['triggerDetail'])
const emit = defineEmits(['delete'])
const { linkageDetail, refresh } = useDetailState()

const triggerEventModalOpen = ref(false)
const triggerExpressionModalOpen = ref(false)
const triggerOnoffLineModalOpen = ref(false)

function onDelete(type: string, id: any) {
  emit('delete', [type, id])
}

function onAddEvent() {
  triggerEventModalOpen.value = true
}

function onAddExpression() {
  triggerExpressionModalOpen.value = true
}
function onAddOnoffLine() {
  triggerOnoffLineModalOpen.value = true
}

async function onRefresh() {
  await refresh(linkageDetail.value.linkageId)
}
</script>

<template>
  <a-space direction="vertical" w-full :size="20">
    <a-table
      :data-source="triggerDetail.triggerEvents" :columns="[
        { title: '设备名称', dataIndex: 'deviceName' },
        { title: '设备编号', dataIndex: 'deviceCode' },
        { title: '事件名称', dataIndex: 'eventName' },
        { title: '事件编号', dataIndex: 'eventCode' },
        { title: '出参规则表达式', dataIndex: 'outputDataExpression' },
        { title: '操作', dataIndex: 'operation' },
      ]" :pagination="false" :scroll="{ y: 220 }" bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a-space direction="horizontal">
            <!-- <a-button type="link">
              编辑
            </a-button> -->
            <a-popconfirm title="确定删除？" @confirm="() => onDelete('triggerEvents', record.id)">
              <a-button type="link" danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
      <template #title>
        <div w-full flex justify-between items-center>
          <span text-16px font-bold>设备事件</span>
          <a-button type="primary" @click="onAddEvent">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
        </div>
      </template>
    </a-table>

    <a-table
      :data-source="triggerDetail.triggerExpressions" :columns="[
        { title: '设备名称', dataIndex: 'deviceName' },
        { title: '设备编号', dataIndex: 'deviceCode' },
        { title: '规则表达式', dataIndex: 'deviceModelExpressionName' },
        { title: '操作', dataIndex: 'operation' },
      ]" :pagination="false" :scroll="{ y: 220 }" bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a-space direction="horizontal">
            <a-popconfirm title="确定删除？" @confirm="() => onDelete('triggerExpressions', record.id)">
              <a-button type="link" danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
      <template #title>
        <div w-full flex justify-between items-center>
          <span text-16px font-bold>规则表达式</span>
          <a-button type="primary" @click="onAddExpression">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
        </div>
      </template>
    </a-table>

    <a-table
      :data-source="triggerDetail.triggerOnofflines" :columns="[
        { title: '设备名称', dataIndex: 'deviceName' },
        { title: '设备编号', dataIndex: 'deviceCode' },
        { title: '上下线',
          dataIndex: 'onoffline',
          customRender({ record }) {
            const map = new Map([
              [0, '下线'],
              [1, '上线'],
              [2, '上下线'],
            ])
            return map.get(record.onoffline)
          } },
        { title: '操作', dataIndex: 'operation' },
      ]" :pagination="false" :scroll="{ y: 220 }" bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a-space direction="horizontal">
            <a-popconfirm title="确定删除？" @confirm="() => onDelete('triggerOnofflines', record.id)">
              <a-button type="link" danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
      <template #title>
        <div w-full flex justify-between items-center>
          <span text-16px font-bold>设备上下线</span>
          <a-button type="primary" @click="onAddOnoffLine">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
        </div>
      </template>
    </a-table>

    <TriggerEventEdit v-model:open="triggerEventModalOpen" :linkage-detail="linkageDetail" @confirm="onRefresh" />
    <TriggerExpressionEdit v-model:open="triggerExpressionModalOpen" :linkage-detail="linkageDetail" @confirm="onRefresh" />
    <TriggerOnofflineEdit v-model:open="triggerOnoffLineModalOpen" :linkage-detail="linkageDetail" @confirm="onRefresh" />
  </a-space>
</template>
