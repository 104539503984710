<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue'
import { useDetailState } from './detail-context'
import ConditionExpressionEdit from './condition-expression-edit.vue'
import ConditionOnofflineEdit from './condition-onoffline-edit.vue'

defineProps(['conditionDetail'])
const emit = defineEmits(['delete'])
const { linkageDetail, refresh } = useDetailState()

const conditionExpressionModalOpen = ref(false)
const conditionOnoffLineModalOpen = ref(false)

function onAddExpression() {
  conditionExpressionModalOpen.value = true
}
function onAddOnoffLine() {
  conditionOnoffLineModalOpen.value = true
}

async function onRefresh() {
  await refresh(linkageDetail.value.linkageId)
}

function onDelete(type: string, id: any) {
  emit('delete', [type, id])
}
</script>

<template>
  <a-space direction="vertical" w-full :size="20">
    <a-table
      :data-source="conditionDetail.conditionOnlines" :columns="[
        { title: '设备名称', dataIndex: 'deviceName' },
        { title: '设备编号', dataIndex: 'deviceCode' },
        { title: '在线/离线',
          dataIndex: 'online',
          customRender({ record }) {
            return record.online ? '在线' : '离线'
          } },
        { title: '持续时长(秒)', dataIndex: 'durationSeconds' },
        { title: '操作', dataIndex: 'operation' },
      ]" :pagination="false" :scroll="{ y: 220 }" bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a-space direction="horizontal">
            <!-- <a-button type="link">
              编辑
            </a-button> -->
            <a-popconfirm title="确定删除？" @confirm="() => onDelete('conditionOnlines', record.id)">
              <a-button type="link" danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
      <template #title>
        <div w-full flex justify-between items-center>
          <span text-16px font-bold>持续在线离线</span>
          <a-button type="primary" @click="onAddOnoffLine">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
        </div>
      </template>
    </a-table>

    <a-table
      :data-source="conditionDetail.conditionExpressions" :columns="[
        { title: '设备名称', dataIndex: 'deviceName' },
        { title: '设备编号', dataIndex: 'deviceCode' },
        { title: '规则表达式', dataIndex: 'deviceModelExpressionName' },
        { title: '操作', dataIndex: 'operation' },
      ]" :pagination="false" :scroll="{ y: 220 }" bordered
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'operation'">
          <a-space direction="horizontal">
            <!-- <a-button type="link">
              编辑
            </a-button> -->
            <a-popconfirm title="确定删除？" @confirm="() => onDelete('conditionExpressions', record.id)">
              <a-button type="link" danger>
                删除
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
      <template #title>
        <div w-full flex justify-between items-center>
          <span text-16px font-bold>规则表达式</span>
          <a-button type="primary" @click="onAddExpression">
            <template #icon>
              <PlusOutlined />
            </template>
            新增
          </a-button>
        </div>
      </template>
    </a-table>

    <ConditionExpressionEdit v-model:open="conditionExpressionModalOpen" :linkage-detail="linkageDetail" @confirm="onRefresh" />
    <ConditionOnofflineEdit v-model:open="conditionOnoffLineModalOpen" :linkage-detail="linkageDetail" @confirm="onRefresh" />
  </a-space>
</template>

<style lang="scss" scoped>

</style>
