<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import _, { some } from 'lodash-es'
import { queryDevicesApi } from '@/api/device'
import { getDeviceModelExpressionApi, getDeviceModelsApi } from '@/api/device-model'
import { updateLinkageConfigDetailApi } from '@/api/linkage'

const props = defineProps(['linkageDetail'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const formRef = ref<FormInstance>()
const deviceModelCode = ref()
const localConditionExpressions = ref<Record<string, any>>({
  deviceCode: '',
  deviceName: '',
  deviceModelExpressionName: '',
  deviceModelExpressionId: undefined,
})
const deviceModelList = ref<any[]>([])
const devices = ref<any[]>([])
const modelExpressions = ref<any[]>([])
// const { addTriggerEvent, linkageDetail } = useDetailState()

watch(open, async (newOpen) => {
  if (newOpen) {
    deviceModelList.value = await getDeviceModelsApi()
    localConditionExpressions.value = {
      deviceCode: '',
      deviceName: '',
      deviceModelExpressionName: '',
      deviceModelExpressionId: undefined,
    }
    deviceModelCode.value = undefined
  }
})

function onSubmit() {
  formRef.value?.validate().then(async () => {
    const nextDetail = _.cloneDeep(props.linkageDetail)
    localConditionExpressions.value.linkageId = nextDetail.linkageId
    nextDetail.conditionExpressions.push(localConditionExpressions.value)
    await updateLinkageConfigDetailApi(nextDetail)
    emit('confirm')
    open.value = false
  })
}

async function onChangeDeviceModel(deviceModelCode: any, record: any) {
  const res = await queryDevicesApi({ size: 1000, deviceModelCode, current: 1 })
  devices.value = res.records
}

async function onChangeDevice(deviceCode: any, record: any) {
  localConditionExpressions.value.deviceName = record.deviceName
  modelExpressions.value = await getDeviceModelExpressionApi(record.deviceModelCode)
}

function onChangeExpression(expressionId: any, record: any) {
  localConditionExpressions.value.deviceModelExpressionName = record.expressionName
}

const okButtonDisabled = computed(() => {
  const requiredKeys = ['deviceCode', 'deviceName', 'deviceModelExpressionName', 'deviceModelExpressionId']
  return some(requiredKeys, item => (localConditionExpressions.value[item] === undefined || localConditionExpressions.value[item] === ''))
})
</script>

<template>
  <a-modal v-model:open="open" title="规则表达式" :centered="true" :ok-button-props="{ disabled: okButtonDisabled }" @ok="onSubmit">
    <a-form ref="formRef" :model="localConditionExpressions" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="设备模型">
        <a-select v-model:value="deviceModelCode" :options="deviceModelList" :field-names="{ label: 'deviceModelName', value: 'deviceModelCode' }" @change="onChangeDeviceModel" />
      </a-form-item>
      <a-form-item v-if="devices.length > 0" label="设备" required>
        <a-select v-model:value="localConditionExpressions.deviceCode" :options="devices" :field-names="{ label: 'deviceName', value: 'deviceCode' }" @change="onChangeDevice" />
      </a-form-item>
      <a-form-item v-if="modelExpressions.length > 0" label="表达式" required>
        <a-select v-model:value="localConditionExpressions.deviceModelExpressionId" :options="modelExpressions" :field-names="{ label: 'expressionName', value: 'id' }" @change="onChangeExpression" />
      </a-form-item>
      <a-alert v-if="okButtonDisabled" message="缺少必须字段，无法添加" type="warning" show-icon />
    </a-form>
  </a-modal>
</template>

<style lang="scss" scoped>

</style>
