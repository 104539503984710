<script setup lang="ts">
import type { FormInstance } from 'ant-design-vue'
import { cloneDeep, some } from 'lodash-es'
import { queryDevicesApi } from '@/api/device'
import { getDeviceModelsApi } from '@/api/device-model'
import { updateLinkageConfigDetailApi } from '@/api/linkage'

const props = defineProps(['linkageDetail'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const formRef = ref<FormInstance>()
const deviceModelCode = ref()
const localActionVideos = ref<Record<string, any>>({
  videoDeviceCode: '',
  deviceName: '',
})
const deviceModelList = ref<any[]>([])
const devices = ref<any[]>([])

watch(open, async (newOpen) => {
  if (newOpen) {
    deviceModelList.value = await getDeviceModelsApi()
    localActionVideos.value = {
      videoDeviceCode: '',
      deviceName: '',
    }
    deviceModelCode.value = undefined
  }
})

function onSubmit() {
  formRef.value?.validate().then(async () => {
    const nextDetail = cloneDeep(props.linkageDetail)
    localActionVideos.value.linkageId = nextDetail.linkageId
    nextDetail.actionVideos.push(localActionVideos.value)
    await updateLinkageConfigDetailApi(nextDetail)
    emit('confirm')

    open.value = false
  })
}

async function onChangeDeviceModel(deviceModelCode: any, xx: any) {
  const res = await queryDevicesApi({ size: 1000, deviceModelCode, current: 1 })
  devices.value = res.records
  localActionVideos.value.videoDeviceCode = ''
  localActionVideos.value.deviceName = ''
}

async function onChangeDevice(deviceCode: any, record: any) {
  localActionVideos.value.deviceName = record.deviceName
}

const okButtonDisabled = computed(() => {
  const requiredKeys = ['videoDeviceCode', 'deviceName']
  return some(requiredKeys, item => (localActionVideos.value[item] === '' || localActionVideos.value[item] === ''))
})
</script>

<template>
  <a-modal v-model:open="open" title="设备事件" :centered="true" @ok="onSubmit">
    <a-form ref="formRef" :model="localActionVideos" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="设备模型">
        <a-select v-model:value="deviceModelCode" :options="deviceModelList" :field-names="{ label: 'deviceModelName', value: 'deviceModelCode' }" @change="onChangeDeviceModel" />
      </a-form-item>
      <a-form-item v-if="devices.length > 0" label="设备" required>
        <a-select v-model:value="localActionVideos.videoDeviceCode" :options="devices" :field-names="{ label: 'deviceName', value: 'deviceCode' }" @change="onChangeDevice" />
      </a-form-item>
      <a-alert v-if="okButtonDisabled" message="缺少必须字段，无法添加" type="warning" show-icon />
    </a-form>
  </a-modal>
</template>

<style lang="scss" scoped>

</style>
