import type { Ref } from 'vue'
import { cloneDeep } from 'lodash-es'
import { updateLinkageConfigDetailApi } from '@/api/linkage'

function useDetailStore(linkageDetail: Ref<any>, refresh: Function) {
  //   return { name, video, abilities, initParamModel, propertyBindRuleModel, thingModel }
  async function addTriggerEvent(triggerEvent: any) {
    const nextDetail = cloneDeep(linkageDetail.value)
    nextDetail.triggerEvents.push(triggerEvent)
    await updateLinkageConfigDetailApi(nextDetail)
    await refresh(nextDetail.linkageId)
  }

  return { linkageDetail, addTriggerEvent, refresh }
}

const [useDetailProvider, useDetailInject] = createInjectionState(useDetailStore)

export { useDetailProvider }

export const useDetailState = (): ReturnType<typeof useDetailStore> => useDetailInject()!
