<script setup lang="ts">
import { Button, Dropdown, Menu, Space, type TablePaginationConfig, Tag } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import update from './components/update.vue'
import Detail from './components/detail.vue'
import { deleteLinkageConfigApi, enableLinkageApi, queryLinkageConfigsApi } from '@/api/linkage'

const modal = useModal()
const updateVisible = ref(false)
const choosedLinkage = ref()
const open = ref(false)
const params = ref<any>({ current: 1, size: 20 } as any)
const search = ref<TableSearchType[]>([
  { label: '规则名称', name: 'linkageName', placeholder: '请输入规则名称', required: false, type: 'input' },
  {
    label: '启用状态',
    name: 'enabled',
    placeholder: '请选择',
    required: false,
    type: 'select',
    options: [
      { label: '启用', value: 'true' },
      { label: '禁用', value: 'false' },
    ],
  },
])
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '规则名称', dataIndex: 'linkageName' },
    { title: '启用状态', dataIndex: 'enabled', customRender({ record }) {
      const enabled = record.enabled
      return h(Tag, { color: enabled ? 'green' : 'error' }, () => enabled ? '启用' : '禁用')
    } },
    { title: 'cron表达式', dataIndex: 'cron', customRender: ({ record }) => record.cron ?? '无' },
    { title: '开始时间', dataIndex: 'startAt', customRender: ({ record }) => record.startAt ? useDateFormat(record.startAt, 'YYYY年MM月DD日').value : '不限' },
    { title: '结束时间', dataIndex: 'endAt', customRender: ({ record }) => record.endAt ? useDateFormat(record.endAt, 'YYYY年MM月DD日').value : '不限' },
    { title: '备注', dataIndex: 'description' },
    { title: '操作', customRender({ record }) {
      return h(Space, { direction: 'horizontal', wrap: false }, () => [
        h(Button, { type: 'link', size: 'small', onClick: () => openDetailPage(record) }, () => '详情'),
        h(Button, { type: 'link', size: 'small', danger: record.enabled, onClick: () => enableLinkage(record) }, () => record.enabled ? '禁用' : '启用'),
        h(
          Dropdown,
          {
            trigger: 'click',
            overlay: h(Menu, { items: [
              { key: 1, label: '编辑', onClick: () => onUpdateLinkage(record) },
              { key: 2, label: '删除', style: { color: 'red' }, async onClick() {
                await onDeleteLinkage(record.id)
              } },
            ],
            }),
          },
          () => h(Button, { type: 'link', size: 'small' }, () => '更多'),
        ),
      ])
    } },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: {
    position: ['bottomLeft'],
    pageSize: 20,
    pageSizeOptions: ['20', '30', '40', '50'],
    total: 0,
    current: 1,
    showTotal: total => `共${total}条`,
  },
})

onMounted(refresh)

async function refresh() {
  state.config.loading = true
  const { records, current, size, total } = await queryLinkageConfigsApi(params.value)
  state.data = records
  const pagination = state.pagination as TablePaginationConfig
  pagination.current = current
  pagination.pageSize = size
  pagination.total = total

  setTimeout(() => {
    state.config.loading = false
  }, 500)
}

async function onSearch(form: any) {
  params.value = { ...form, current: 1, size: (state.pagination as TablePaginationConfig).pageSize ?? 20 }
  await refresh()
}

async function onTablePageChange(current: number, pageSize: number) {
  (state.pagination as TablePaginationConfig).current = current;
  (state.pagination as TablePaginationConfig).pageSize = pageSize
  params.value.current = current
  params.value.size = pageSize

  await refresh()
}

async function enableLinkage(record: any) {
  const res = await enableLinkageApi({ linkageId: record.id, enable: !record.enabled })
  if (res)
    await refresh()
}

async function onDeleteLinkage(id: number) {
  modal.confirm({ title: '确定要删除？', centered: true, async onOk() {
    const success = await deleteLinkageConfigApi(id)
    if (success)
      await refresh()
  } })
}

function onUpdateLinkage(linkage: any) {
  updateVisible.value = true
  choosedLinkage.value = linkage
}

function onAddLinkage() {
  updateVisible.value = true
}

async function onUpdateConfirm() {
  updateVisible.value = false
  choosedLinkage.value = undefined
  await refresh()
}

function openDetailPage(record: any) {
  // pageType.value = 'detail'
  open.value = true
  choosedLinkage.value = record
}
</script>

<template>
  <page-container>
    <content-header title="联动管理" />
    <flex-content>
      <div h-full overflow-hidden flex flex-col>
        <div rounded mb-10px p-12px style="background: var(--bg-color);">
          <search-form :search="search" @search="onSearch" />
        </div>
        <table-layout>
          <template #headerLeft>
            <a-space size="middle">
              <a-button type="primary" @click="onAddLinkage">
                <template #icon>
                  <PlusOutlined />
                </template>
                新增联动规则
              </a-button>
            </a-space>
          </template>
          <template #content>
            <base-table v-bind="state" @page-change="onTablePageChange" />
          </template>
        </table-layout>
      </div>
    </flex-content>
    <Detail v-model:open="open" :linkage="choosedLinkage" />
    <update v-model:open="updateVisible" :linkage="choosedLinkage" @confirm="onUpdateConfirm" />
  </page-container>
</template>

<style lang="scss" scoped>

</style>
